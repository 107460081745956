<template>
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <!-- 头部面包屑 -->
            <div class="framePage-title">
                <span>
                    <em>当前位置：</em>
                    <a href="javascript:;">报名管理</a>
                    <i>></i>
                    <a href="javascript:;" class="cur-a">报名列表</a>
                </span>
            </div>
            <!-- 主体区域 -->
            <div class="framePage-body">
                <!-- 搜索区域 -->
                <div class="operationControl" style="display: flex; flex-direction: column; align-items: flex-start">
                    <div class="searchbox">
                        <div title="招生任务名称" class="searchboxItem ">
                            <span class="itemLabel" style="min-width: 8em;">招生任务名称:</span>
                            <el-input v-model="searchForm.projectName" placeholder="请输入招生任务名称" type="text" clearable size="small"/>
                        </div>
                        <div class="searchboxItem">
                            <el-button type="primary" class="bgc-bv" round @click="getListData(1)">查询</el-button>
                            <el-button type="primary" class="bgc-bv" round @click="newlyAdded(null,1)">新增</el-button>
                        </div>
                    </div>
                </div>

                <!-- 列表区域 -->
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <el-table
                            ref="multipleTable"
                            :data="listData"
                            :height="tableHeight"
                            size="small"
                            tooltip-effect="dark"
                            style="width: 100%"
                            :header-cell-style="tableHeader"
                            stripe
                        >
                            <el-table-column
                                label="序号"
                                align="center"
                                type="index"
                                :index="indexMethod"
                                width="50"
                            />
                            <el-table-column
                                label="招生任务名称"
                                prop="projectName"
                                min-width="220px"
                                align="center"
                            />
                            <el-table-column
                                label="培训分类"
                                prop="categoryName"
                                min-width="100px"
                                align="center"
                            />
                            <el-table-column
                                label="培训工种"
                                prop="occupationName"
                                min-width="130px"
                                align="center"
                            />
                            <el-table-column
                                label="招生时间"
                                min-width="150px"
                                align="center"
                            >
                                <template v-slot="{row}">
                                    {{row.startDate}}~{{row.endDate}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="计划人数"
                                prop="projectPeople"
                                align="center"
                            />
                            <el-table-column
                                label="已报人数"
                                prop="projectApplyPeople"
                                align="center"
                            />
                            <el-table-column
                                label="操作"
                                width="300px"
                                fixed="right"
                                align="center"
                            >
                                <template v-slot="{row}">
                                    <el-button type="text" size="mini" style="padding: 0 5px;" @click="newlyAdded(row,3)">复制</el-button>
                                    <el-button type="text" size="mini" style="padding: 0 5px;" @click="newlyAdded(row,2)">编辑</el-button>
                                    <el-button type="text" size="mini" style="padding: 0 5px;" @click="deleteTraining(row)">删除</el-button>
                                    <el-button v-if="row.releaseState==='10'" type="text" size="mini" style="padding: 0 5px;" @click="release(row)">发布</el-button>
                                    <el-button v-else type="text" size="mini" style="padding: 0 5px;" @click="release(row)">取消发布</el-button>
                                    <el-button type="text" size="mini" style="padding: 0 5px;" @click="toStudentManagement(row)">查看学员</el-button>
<!--                                    <el-button type="text" size="mini"-->
<!--                                               style="padding: 0 5px;" @click="configQuestionBank(row)">配置题库</el-button>-->
                                </template>
                            </el-table-column>
                            <Empty slot="empty" />
                        </el-table>
                    </div>
                </div>

                <!-- 分页器 -->
                <PageNum :apiData="pagination" @sizeChange="sizeChange" @getData="currentChange"/>
            </div>
        </div>

        <!-- 新增对话框 -->
        <el-dialog
            :title="dialogTitle"
            :visible.sync="dialogVisible"
            width="1000px"
            @closed="dialogClosed"
        >
            <el-form ref="dialogForm" :model="dialogForm" :rules="rules" size="small" label-width="120px">
                <el-form-item label="招生任务名称" prop="projectName">
                    <el-input @focus="clearValid('projectName')" v-model="dialogForm.projectName" placeholder="请输入招生任务名称"></el-input>
                </el-form-item>
                <el-form-item label="培训工种" prop="occupationId">
                    <el-select clearable placeholder="请选择培训工种" :disabled="dialogForm.releaseState==='20'" @focus="clearValid('occupationId')" @change="occupationChange" v-model="dialogForm.occupationId">
                        <el-option
                            v-for="item in trainingTypeList"
                            :key="item.occupationId"
                            :label="item.occupationName"
                            :value="item.occupationId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="培训分类">
                    <el-select clearable disabled v-model="dialogForm.categoryId">
                        <el-option
                            v-for="item in trainingTypeList"
                            :key="item.categoryId"
                            :label="item.categoryName"
                            :value="item.categoryId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="开始时间" prop="startDate">
                    <el-date-picker
                        @focus="clearValid('startDate')"
                        v-model="dialogForm.startDate"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="请选择开始时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="截止时间" prop="endDate">
                    <el-date-picker
                        @focus="clearValid('endDate')"
                        v-model="dialogForm.endDate"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="请选择截止时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="考试时间">
                    <el-date-picker
                        @focus="clearValid('examDate')"
                        v-model="dialogForm.examDate"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="请选择考试时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="计划人数">
                    <el-input placeholder="请输入计划人数" @focus="clearValid('projectPeople')" @input="filter('projectPeople')" v-model="dialogForm.projectPeople">
                        <template slot="append">人</template>
                    </el-input>
                </el-form-item>

                <el-form-item
                label="封面图片"
                prop="cover"
                class="form-item"
              >
                <el-upload
                  :on-change="handleAvatarSuccess2"
                  :before-upload="beforeAvatarUpload2"
                  :http-request="$requestMine"
                  :show-file-list="false"
                  class="img-el-upload"
                  action
                  accept="image/png, image/gif, image/jpg, image/jpeg"
                    
                >
                  <el-image
                    :src="
                      dialogForm.coverUrl || require('@/assets/institutional/customProgramsRegistration.png')
                    "
                    fit="contain"
                    class="imgCenter"
                  ></el-image>
                </el-upload>
              </el-form-item>


                <el-form-item label="招生说明">
                    <div ref="editors" style="width: 100%; position: relative; z-index:10;"/>
                </el-form-item>
                <el-form-item label="报名限制">
                    <el-checkbox-group v-model="dialogForm.restricted">
                        <el-checkbox :label="true">报名超过计划人数不允许继续报名</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button class="customBtn" round @click="dialogClosed">取 消</el-button>
                <el-button class="customBtn" round type="primary" @click="submit">保 存</el-button>
            </div>
        </el-dialog>
      <el-dialog
          :visible.sync="questionBankShow"
          top="5%"
          width="40%"
          title="配置题库"
          :center="true"
          :before-close="closeQuestionBank"
      >
        <div style="height: 150px">
          <div class="ovy-a">
            <el-form
                :rules="questionBankFormRules"
                label-width="120px"
                ref="questionBank"
                :model="questionBankForm"
                class="docsDialog"
            >
              <el-form-item label="题库有效期限" prop="expirationDate" >
                <el-date-picker
                  size="small"
                  v-model="questionBankForm.expirationDate"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
              ></el-date-picker>
              </el-form-item>
              <el-form-item label="搜索题库" prop="questionBankId" >
                <el-select
                    size="small"
                    v-model="questionBankForm.questionBankId"
                    :remote-method="getQuestionBankList"
                    remote
                    filterable
                    clearable
                    placeholder="请至少输入两个字搜索"
                >
                  <el-option
                      v-for="item in questionBankList"
                      :key="item.questionBankId"
                      :label="item.questionBankName"
                      :value="item.questionBankId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <span
            slot="footer"
            class="dialog-footer"
            style="display: flex; justify-content: center"
        >
          <div>
            <el-button @click="closeQuestionBank">取消</el-button>
            <el-button class="bgc-bv" @click="saveQuestionBank">确定</el-button>
          </div>
        </span>
      </el-dialog>
    </div>
</template>

<script>
    import { resetKeepAlive } from "@/utils/common";
    import Empty from "@/components/Empty.vue";
    import PageNum from "@/components/PageNum.vue";
    import List from "@/mixins/List";
    // 引入富文本
    import E from "wangeditor";
    let editor=null;
    export default {
        name:"customProgramsRegistrationList",
        components:{
            Empty,
            PageNum
        },
        mixins:[List],
        data(){
            return{
                questionBankList: [],
                questionBankForm: {
                  questionBankIdBefore: null,
                  questionBankId: null,
                  expirationDate: null,
                  projectId: null,
                },
                questionBankShow: false,
                // 搜索区域表单
                searchForm:{
                    projectName:"",//招生任务名称
                },
                //培训列表数据
                listData:[],
                // 分页器数据
                pagination:{
                    total: 0,//总条数
                    size: 10,//每页几条数据
                    current: 1//当前页
                },
                // 对话框表单信息
                dialogForm:{
                    projectId:"",//id
                    projectName:"",//招生任务名称
                    occupationId:"",//培训工种
                    categoryId:"",//培训分类
                    examDate:"",//考试时间
                    projectPeople:"",//计划人数
                    recruitStudentsExplain:"",//招生说明
                    coverUrl:"",//封面图片
                    cover:"",//封面图片
                    startDate:"",//开始时间
                    endDate:"",//截止时间
                    releaseState:null,//发布状态
                    restricted:false,//报名超过计划人数不允许继续报名
                },
                // 培训工种数据
                trainingTypeList:[],
                //对话框可见性
                dialogVisible:false,
                // 对话框是添加还是编辑
                dialogType:1, // 1-添加  2-编辑 3-复制
                dialogTitle:"",
                // 表单规则
                rules:{
                    projectName:[
                        {required:true,message:"请输入招生任务名称",trigger:"blur"}
                    ],
                    occupationId:[
                        {required:true,message:"请选择培训工种",trigger:"change"}
                    ],
                    startDate:[
                        {required:true,message:"请选择开始时间",trigger:"change"}
                    ],
                    endDate:[
                        {required:true,message:"请选择截止时间",trigger:"change"}
                    ],
                    examDate:[
                        {required:true,message:"请选择考试时间",trigger:"change"}
                    ],
                    projectPeople:[
                        {required:true,message:"请输入计划人数",trigger:"blur"}
                    ],
                },
                questionBankFormRules: {
                  expirationDate: [
                    {
                      required: true,
                      trigger: "change",
                      message: "请选择题库有效期限",
                    },
                  ],
                  questionBankId: [
                    {
                      required: true,
                      message: "请选择题库",
                      trigger: "change",
                    },
                  ],
                }
            }
        },
        methods:{
          // 序号
          indexMethod(index) {
            return (this.pagination.current - 1) * this.pagination.size + index + 1;
          },
          getQuestionBankList(query) {
            if (query.trim().length >= 2) {
              this.$post("/biz/ct/project/selectQuestionBank", { questionBankName: query })
                  .then(res => {
                    if (res.status === "0") {
                      this.questionBankList = res.data || [];
                    }
                  });
            } else {
              this.questionBankList = [];
            }
          },
          closeQuestionBank() {
            this.questionBankShow = false;
            this.questionBankList = [];
            this.questionBankForm = {
              questionBankId: null,
              questionBankIdBefore: null,
              expirationDate: null,
              projectId: null,
            };
            this.$refs.questionBank.clearValidate();
          },
          saveQuestionBank() {
            this.$refs.questionBank.validate(valid => {
              if (valid) {
                if (this.questionBankForm.questionBankIdBefore
                && this.questionBankForm.questionBankIdBefore !==
                this.questionBankForm.questionBankId) {
                  this.$confirm(
                      "题库已经赠送，如果重新选择则将清除学员的练习题库，数据将不能恢复，是否确定重新选择?",
                      "提示",
                      {
                        showClose: false, //是否显示右上角关闭按钮
                        closeOnClickModal: false, //是否可通过点击遮罩关闭 MessageBox
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        confirmButtonClass: "bgc-bv"
                      }
                  ).then(() => {
                        this.startConfigQuestionBank();
                      }).catch(() => {});
                } else {
                  this.startConfigQuestionBank();
                }
              }
            })
          },
          startConfigQuestionBank() {
            this.$post("/biz/ct/project/configQuestionBank",this.questionBankForm).then(res=>{
              if (res.status === "0") {
                this.$message.success("配置成功");
                this.getListData(1);
                this.questionBankShow = false;
              }
            });
          },
            // 获取列表数据
            getListData(current){
                const {searchForm,pagination}=this;
                if(current) this.pagination.current=current;
                this.$post("/biz/ct/project/ctProjectList",{
                    pageNum: pagination.current,
                    pageSize: pagination.size,
                    projectName:searchForm.projectName.trim()
                }).then(res=>{
                    this.listData=res?.data?.list||[];
                    pagination.total=res?.data?.total||0;
                }).catch(e=>{
                    console.error("获取列表数据出错",e);
                });
            },
            // 新增或者编辑
            newlyAdded(row,dialogType){
                console.log(row)
                this.dialogType=dialogType;
                if(dialogType===1){
                    this.dialogTitle ='新增报名信息'                    
                }else if(dialogType===2){
                    this.dialogTitle ='编辑报名信息'
                }else{
                    this.dialogTitle ='复制报名信息'
                }
                if(!row){
                    // 新增                   
                    this.dialogVisible=true;
                    this.$nextTick(()=>{
                        this.initE();
                    });
                }else{
                    // 编辑
                    // 数据回显
                    const {projectId}=row;
                    this.echoData(projectId);
                }
            },
            // 编辑数据回显
            echoData(projectId){
                const {dialogForm}=this;
                // 需要做数据处理的字段
                const arr=["startDate","endDate","examDate"];
                this.$post("/biz/ct/project/infoCtProject",{
                    projectId
                }).then(res=>{
                    Object.keys(dialogForm).forEach(key=>{
                        dialogForm[key]=res.data[key];
                        if(arr.includes(key) && res.data[key]) {
                            dialogForm[key]=dialogForm[key].replaceAll("/","-");
                        }
                    });
                    this.dialogVisible=true;
                    this.$nextTick(()=>{
                        this.initE();
                        editor.txt.html(res.data.recruitStudentsExplain);
                    });
                }).catch(e=>{
                    console.error("回显数据出错",e);
                });
            },
            // 删除
            deleteTraining(row){
                const {projectId}=row;
                this.$confirm('是否删除？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    confirmButtonClass:"bgc-bv",
                }).then(() => {
                    // 数据回显
                    this.$post("biz/ct/project/deleteCtProject",{
                        projectId
                    }).then(res=>{
                        this.getListData();
                        this.$message.success("删除成功");
                    });
                });
            },
            // 对话框提交
            submit(){
                const {dialogForm,dialogType}=this;
                this.$refs.dialogForm.validate(valid=>{
                    if(valid){
                        if(dialogType===1){
                            delete dialogForm.projectId;
                            // 新增
                            this.$post("/biz/ct/project/addCtProject",dialogForm).then(()=>{
                                this.getListData();
                                this.$message.success("新增成功");
                                this.dialogVisible=false;
                            });
                        }else if(dialogType===2) {
                            // 编辑
                            this.$post("/biz/ct/project/updateCtProject",dialogForm).then(res=>{
                                this.getListData();
                                this.$message.success("修改成功");
                                this.dialogVisible=false;
                            });
                        }else if(dialogType===3) {
                            // 复制
                            delete dialogForm.projectId;
                            this.$post("/biz/ct/project/addCtProject",dialogForm).then(res=>{
                                this.getListData();
                                this.$message.success("复制成功");
                                this.dialogVisible=false;
                            });
                        }
                    }
                });
            },
            // 发布/取消发布
            release(row){
                const {projectId,releaseState}=row;
                this.$post("/biz/ct/project/releaseCtProject",{
                    projectId,
                    releaseState:releaseState==="10"?"20":"10"
                }).then(()=>{
                    this.getListData();
                    this.$message.success("操作成功");
                });
            },
            // 培训工种改变
            occupationChange(occupationId){
                const {dialogForm,trainingTypeList}=this;
                if(!occupationId) return dialogForm.categoryId="";
                trainingTypeList.forEach(item=>{
                    if(item.occupationId===occupationId){
                        dialogForm.categoryId=item.categoryId;
                    }
                });
            },
            // 获取工种类型
            getTrainingType(){
                this.$post("/biz/ct/occupation/usable").then(res=>{
                    this.trainingTypeList=res.data || [];
                }).catch(e=>{
                    console.error("获取培训工种出错",e);
                });
            },
            // 清除不合法数据
            filter(key){
                const {dialogForm}=this;
                const reg=/\D/g;
                dialogForm[key]=dialogForm[key].replace(reg,"");
            },
            // 清除某一项表单校验结果
            clearValid(key){
                this.$refs.dialogForm.clearValidate(key);
            },
            // 初始化富文本
            initE(){
                if(editor) return;
                // 创建富文本编辑器
                editor = new E(this.$refs.editors);
                // 富文本变化赋值
                editor.config.onchange = (value) => {
                    this.dialogForm.recruitStudentsExplain = value;
                };
                // 富文本编辑器的菜单配置
                editor.config.menus = [
                    "head", // 标题
                    "bold", // 粗体
                    "fontSize", // 字号
                    "italic", // 斜体
                    "underline", // 下划线
                    "strikeThrough", // 删除线
                    "foreColor", // 文字颜色
                    "backColor", // 背景颜色
                    "list", // 列表
                    "justify", // 对齐方式
                    "quote", // 引用
                    "emoticon", // 表情
                    "image", // 插入图片
                    "table", // 表格
                    "undo", // 撤销
                    "redo", // 重复
                ];
                // 富文本上传图片大小限制（2M）
                editor.config.uploadImgMaxSize = 2 * 1024 * 1024;
                // 关闭粘贴样式过滤
                editor.config.pasteFilterStyle = false;
                // 忽略粘贴的图片
                editor.config.pasteIgnoreImg = true;
                // 富文本上传图片类型限制
                editor.config.uploadImgAccept = ['jpg', 'jpeg', 'png'];
                // 图片上传个数限制
                editor.config.uploadImgMaxLength = 1;
                // 自定义富文本图片上传
                editor.config.customUploadImg = (resultFiles, insertImgFn)=> {
                    // resultFiles 是 input 中选中的文件列表
                    // insertImgFn 是获取图片 url 后，插入到编辑器的方法
                    const index=resultFiles[0].name.lastIndexOf(".")+1;
                    const extension=resultFiles[0].name.slice(index);
                    // 定义formData
                    const formData=new FormData();
                    formData.append("file",resultFiles[0]);
                    formData.append("fileType",extension);
                    formData.append("folder ", "MATERIALS");
                    formData.append("isPublic ", true);
                    // 发起网络请求，上传图片
                    this.$Postformat("/sys/upload",formData).then(res=>{
                        // 上传图片，返回结果，将图片插入到编辑器中
                        insertImgFn(res.data.fileURL);
                    }).catch(e=>{
                        console.error("上传图片出错",e);
                        this.$message.error("上传失败");
                    });
                }
                // 配置粘贴文本的内容处理
                editor.config.pasteTextHandle =  (pasteStr)=> {
                    // 对粘贴的文本进行处理，然后返回处理后的结果
                    // 如果没有内容不进行处理
                    if (pasteStr === "" && !pasteStr) return "";
                    // 1.去掉换行符、class
                    const stringStripper = /(\n|\r| class=(")?Mso[a-zA-Z]+(")?)/g;
                    let output = pasteStr.replace(stringStripper, " ");
                    // 2.带Word生成的HTML注释
                    const commentSripper = new RegExp("<!--(.*?)-->", "g");
                    output = output.replace(commentSripper, "");
                    // 3.删除标签，保留内容（如果有）
                    const tagStripper = new RegExp("<(/)*(meta|link|span|\\?xml:|st1:|o:|font)(.*?)>","gi");
                    output = output.replace(tagStripper, "");
                    // 4.删除标签“<style（.）style（.）>”之间的所有内容
                    let badTagsReg;
                    const badTags = [
                        "style",
                        "script",
                        "applet",
                        "embed",
                        "noframes",
                        "noscript",
                    ];
                    for (let i = 0; i < badTags.length; i++) {
                        badTagsReg = new RegExp("<" + badTags[i] + ".*?" + badTags[i] + "(.*?)>","gi");
                        output = output.replace(badTagsReg, "");
                    }
                    // 5.删除“style=…”的属性
                    let attributeStripper;
                    const badAttributes = ["style", "start"];
                    for (let i = 0; i < badAttributes.length; i++) {
                        attributeStripper = new RegExp(" " + badAttributes[i] + '="(.*?)"',"gi");
                        output = output.replace(attributeStripper, "");
                    }
                    return output;
                }
                // 创建富文本编辑器
                editor.create();
            },
            // 跳转到学员管理页面
            toStudentManagement(row){
                const {projectId,projectName}=row;
                this.$router.push({
                    path:"/web/studentManagement",
                    query:{
                        projectId,
                        projectName
                    }
                });
            },
            configQuestionBank(row) {
              this.questionBankForm.projectId = row.projectId;
              if (row.questionBankId) {
                this.questionBankList = [
                  {
                    questionBankId: row.questionBankId,
                    questionBankName: row.questionBankName
                  }
                ];
                this.questionBankForm.expirationDate = row.expirationDate;
                this.questionBankForm.questionBankId = row.questionBankId;
                this.questionBankForm.questionBankIdBefore = row.questionBankId;
              } else {
                let now = new Date();
                now.setTime(now.getTime() + 3600 * 1000 * 24 * 30);
                this.questionBankForm.expirationDate = this.$moment(now).format("YYYY-MM-DD");
                this.questionBankList = [];
              }
              this.questionBankShow = true;
            },

            handleAvatarSuccess2(res) {
                let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
                let formData = new FormData();
                formData.append("folder ", "COURSE_WARE");
                formData.append("file ", res.raw);
                formData.append("fileType ", extension);
                const isLt2M = res.size / 1024 / 1024 < 1;
                if (!isLt2M) {
                    this.$message.error("上传图片大小不能超过 1MB!");
                } else if (
                    ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
                    res.raw.type
                    ) === -1
                ) {
                    this.$message.error("请上传正确的图片格式");
                    return false;
                } else {
                    this.$Postformat("/sys/upload", formData)
                    .then((result) => {
                        this.dialogForm.coverUrl = result.data.fileURL;
                        this.dialogForm.cover = result.data.fileKey;
                    })
                    .catch(() => {
                        setTimeout(() => {
                        this.$message({
                            type: "warning",
                            message: "上传失败",
                        });
                        }, 300);
                    });
                }
            },

            // 对话框关闭，重置表单，清除校验结果
            dialogClosed(){
                editor.txt.clear();
                editor.destroy();
                editor=null;
                this.dialogForm = this.$options.data().dialogForm;
                this.$refs.dialogForm.resetFields();
                this.dialogVisible = false;
            },
            // 当前页改变
            currentChange(current){
                this.pagination.current=current;
                this.getListData();
            },
            // 每页条数改变
            sizeChange(size){
                this.pagination.size=size;
                this.getListData();
            },
            // 设置表格高度
            getTableHeight(opDom = false, page = true) {
                let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
                if (opDom)  tHeight -= 40 + 0.675 * 16 + 1;
                if (page)  tHeight -= 32;
                this.tableHeight = tHeight;
            },
        },
        watch:{
            $route(route,oldRoute){
                if(oldRoute.path==="/web/studentManagement"){
                    this.getListData();
                }
            }
        },
        created(){
            // 获取列表数据
            this.getListData();
            // 获取培训工种数据
            this.getTrainingType();
        },
        beforeDestroy(){
            editor.destroy();
            editor=null;
        },
        beforeRouteLeave: resetKeepAlive,
    }
</script>
<style lang="less" scoped>
.Coursecontract {

  .el-radio {
    .el-radio__label {
      display: none;
    }
    .el-radio__inner{
      width: 18px;
      height: 18px;
      border-color: #777;
    }
    .el-radio__inner:hover{
      border-color: #5c6be8;
    }
    .el-radio__input.is-checked .el-radio__inner{
      border-color: #5c6be8;
      background-color: #5c6be8;
    }
  }
}
</style>
<style lang="less" scoped>
    .templateList{
        .customBtn:nth-child(2){
            background-color: #5C6BE8;
            border: none;
            &:hover{
                background-color: #6875ee;
            }
        }
    }
    .docsDialog .el-form-item {
      margin-bottom: 20px;
    }
</style>
